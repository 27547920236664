jQuery(function($) {


	// --------------
	// Guardia Médica
	// --------------

	$(window).on('load', function() {
		$('body.indice nav.navbar').addClass('navbar-alert');
	});

	$('button.alerta-guardia-cerrar').on('click', function() {
		$('body.indice nav.navbar').removeClass('navbar-alert');
	})


	// ----
	// Menú
	// ----

	var $hamburger = $(".hamburger");

	$hamburger.on("click", function(e) {
		$hamburger.toggleClass("is-active");
	});

	
	// ------------
	// Sicky Header
	// ------------

	const body = document.body;
	const scrollUp = "scroll-up";
	const scrollDown = "scroll-down";
	let lastScroll = 0;

	const navBar = document.getElementById('dintel-derecho');

	window.addEventListener("scroll", () => {

		const currentScroll = window.pageYOffset;

		if (currentScroll == 0) {
			body.classList.remove(scrollUp);
			return;
		}

		if (currentScroll > lastScroll && !body.classList.contains(scrollDown) 
			&& !navBar.classList.contains('show')) {
			// down
			body.classList.remove(scrollUp);
			body.classList.add(scrollDown);
		}
		else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
			// up
			body.classList.remove(scrollDown);
			body.classList.add(scrollUp);
		}

		lastScroll = currentScroll;

	});


	// ----------------------
	// Formulario de búsqueda
	// ----------------------

	$('form#search-form input.form-control').on('focusin', function() {
		$('form#search-form .input-group').addClass('activo');
	});
	$('form#search-form input.form-control').on('focusout', function() {
		$('form#search-form .input-group').removeClass('activo');
	});


	// -----------
	// Slick Slide
	// -----------

	$('.slide-gestion').slick({
		prevArrow: '<div class="slick-arrow-left"><svg width="40" height="40"><use xlink:href="#arrow-left"/></svg></div>',
		nextArrow: '<div class="slick-arrow-right"><svg width="40" height="40"><use xlink:href="#arrow-right"/></svg></div>',
		dots: true,
		slidesToShow: 3,
		responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				centerMode: true,
				centerPadding: '96px'
			}
		},
		{
			breakpoint: 640,
			settings: {
				slidesToShow: 1,
				centerMode: true,
				centerPadding: '32px'
			}
		},
		]
	});


	// ---------------
	// Load more posts
	// ---------------

	function loadMore(paged) {

		// console.log("loadMore called with paged:", paged);

		$.ajax({
			type: 'POST',
			url: WPURLS.siteUrl + '/wp-admin/admin-ajax.php',
			dataType: 'json',
			data: {
				action: 'weichie_load_more',
				postType: postTypeName,
				paged: paged,
			},
			success: function(res) {
				// console.log("AJAX success response:", res);
				if (paged >= res.max) {
					$('#ver-mas-contenedor').hide();
				}
				if (res.html) {
					$('#cards-gestion').append(res.html);
				}
			},
			/*error: function(err) {
				console.error("Error in AJAX request:", err);
			}*/
		});
	}

	if (typeof totalPages !== 'undefined' && totalPages > 1) {
		$('#ver-mas-contenedor').removeClass('d-none');
	}

	let newPage = 1;

	$('#ver-mas-boton').on('click', function() {
		newPage++;
		// console.log("Button clicked, newPage:", newPage);
		loadMore(newPage);
	});


	// -------
	// Masonry
	// -------

	// Fotos
	// -----

	var $grid = $('.grid-fotos').masonry({
		itemSelector: '.grid-item',
	});

	$grid.imagesLoaded().progress( function() {
		$grid.masonry('layout');
	});

	// Videos
	// ------

	function waitForvidLoad(vids, callback) {
		/* if no videos i.e. mobile mode only gifs and jpgs then call callback else masonry breaks.*/
		if (vids.length === 0){
			callback();
		}
		var vidsLoaded = 0;
		vids.on('loadeddata', function() {
			vidsLoaded++;
			if (vids.length === vidsLoaded) {
				callback();
			}
		});
	}

	var $container = $('.grid-videos');
	var vids = $('.grid-videos').find('video');
	waitForvidLoad(vids, function() {
		$container.imagesLoaded(function() {
			$container.masonry({
				itemSelector: '.grid-item',
			});
		});
	});


	// ----------------
	// Galería Fancybox
	// ----------------

	Fancybox.bind("[data-fancybox]", {
	});

	$('.contenido').find('img.alignnone').parent().attr('data-fancybox', 'galeria');
	$('img.size-medium').parent().attr('data-fancybox', 'galeria');
	$('img.size-large').parent().attr('data-fancybox', 'galeria');
	$('.grid-fotos').find('img').parent().attr('data-fancybox', 'galeria');


	// ----------------------
	// Formulario de Contacto
	// ----------------------

	$('#contacto').validate();

	$('#contacto').on('submit', function(e) {

		// if the validator does not prevent form submit
		if (!e.isDefaultPrevented()) {

			var url = WPURLS.themedir + "/contacto.php";

			// POST values in the background the the script URL
			$.ajax({

				type: "POST",
				url: url,
				data: $(this).serialize(),
				success: function(data) {

					console.log(data);

					// data = JSON object that contact.php returns
					// we recieve the type of the message: success x danger and apply it to the 
					if (data == 'success') {
						var messageText = 'El mensaje ha sido enviado. ¡Gracias! En breve nos pondremos en contacto.';
					}
					else {
						var messageText = 'Hubo un error al enviar el mensaje. Por favor, inténtalo más tarde.';
					}

					// let's compose Bootstrap alert box HTML
					var alertBox = '<div class="alert alert-' + data + ' role-alert">' + messageText + '</div>';

					// If we have messageText
					if (messageText) {
						// inject the alert to #notificaciones div in our form
						$('#notificaciones').html(alertBox);
						// empty the form
						$('#contacto')[0].reset();
					}

				}

			});
			
			return false;

		}

	});


	// ---------------
	// Boletín Oficial
	// ---------------

	$('.boletines-lista button.btn').on('click', function() {
		$('.boletines-lista button.btn').removeClass('activo');
		$('.card-header').removeClass('activado');
		$(this).addClass('activo').closest('.card-header').addClass('activado');
	});

	$('a.boletin-vinculo').on('click', function() {
		$('a.boletin-vinculo').removeClass('vinculo-activo');
		$(this).addClass('vinculo-activo');
	});


	// -------------------
	// Enlaces sin destino
	// -------------------

	$('a[href="#"]').on('click', function(event) {
		event.preventDefault();
	})


});